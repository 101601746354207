import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Home from './views/Home'
import Projects from './views/Projects'
import Groove from './views/Groove'
import Nav from './components/Nav'
import { animateIn, animateOut } from "./animations/transitions";
import { Transition, TransitionGroup } from 'react-transition-group';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.navRef = React.createRef();
    }

    render() {
        return (
            <BrowserRouter>
                <div className="app">
                    <Nav ref={this.navRef}/>

                    <Route render={({ location }) => {
                        const { pathname, key } = location

                        return (
                            <TransitionGroup component={null}>
                                <Transition
                                    key={key}
                                    appear={true}
                                    onEnter={(node, appears) => {
                                        animateIn(pathname, node, appears);
                                        this.navRef.current.animate(pathname);
                                    }}
                                    onExit={(node, appears) => animateOut(node, appears)}
                                    timeout={{  exit: 150 }} // Allow page to animate out
                                >
                                    <Switch location={location}>
                                        <Route exact path="/" component={Home}/>
                                        <Route path="/projects" component={Projects} />
                                        <Route path="/groove" component={Groove} />
                                    </Switch>
                                </Transition>
                            </TransitionGroup>
                        )
                    }}/>
                </div>
            </BrowserRouter>
        )
    }
}