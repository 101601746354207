import './styles/Home.css';

import peep from '../assets/peep2.png';

import React, {useEffect} from "react";

import Blob from "../animations/blob.js";
import {Link} from "react-router-dom";

function Home() {
    const animationRef = React.useRef();
    const blobPathRef = React.useRef();
    const blobInstanceRef = React.useRef();

    useEffect(() => {
        document.title = "Luke Ward";

        blobInstanceRef.current = new Blob();
        animationRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(animationRef.current);
    });

    const animate = time => {
        let path = blobInstanceRef.current.getPath();
        blobPathRef.current.setAttribute("d", path);

        animationRef.current = requestAnimationFrame(animate);
    }

    const hoverOverBlob = () => {
        blobInstanceRef.current.setNoiseStep(0.005);
    }

    const hoverOutBlob = () => {
        blobInstanceRef.current.resetNoiseStep();
    }

    return (
        <div className="container">
            <div className="columns">
                <div className="column column-centre">
                    <div className="blob"onMouseEnter={hoverOverBlob} onMouseLeave={hoverOutBlob}>
                        <svg id="background" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" preserveAspectRatio="xMinYMin meet">
                            <path id="blob" fill="#bada55" ref={blobPathRef} />
                        </svg>
                        <img src={peep} alt={"Avatar"} />
                    </div>
                </div>
                <div className="column">
                    <h1>Hi, I'm Luke</h1>
                    <p>
                        I'm a software developer with many years of experience, a first-class computer science degree, and a passion for all aspects of website and mobile app development.
                    </p>
                    <p>
                        I'm also an open source advocate. I think that code should be free and accessible to everyone. I strive to make as much of my work <a href="https://gitlab.com/0x6C77" target="_blank" rel="noreferrer" title="GitLab profile">freely available</a>, which helps share knowledge with others and improve my own code.
                    </p>
                    <p>
                        When I'm not working on personal projects, you can catch me cycling or catching up on the latest album releases from my <Link to="/groove">favorite heavy metal</Link> bands!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Home;