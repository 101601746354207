/**
 * Blob adapted from an excellent tutorial by George Francis
 * https://georgefrancis.dev/writing/build-a-smooth-animated-blob-with-svg-and-js/
 */

import { spline } from '@georgedoescode/spline';
import { createNoise2D } from 'simplex-noise';

export default class Blob {
    constructor() {
        this.noise2D = new createNoise2D();
        this.startingNoiseStep = 0.001;
        this.noiseStep = this.startingNoiseStep;

        this.points = this.createPoints();
    }

    noise(x, y) {
        // return a value at {x point in time} {y point in time}
        return this.noise2D(x, y);
    }

    setNoiseStep(noise) {
        this.noiseStep = noise;
    }

    resetNoiseStep() {
        this.noiseStep = this.startingNoiseStep;
    }

    createPoints() {
        const points = [];
        // how many points do we need
        const numPoints = 5;
        // used to equally space each point around the circle
        const angleStep = (Math.PI * 2) / numPoints;
        // the radius of the circle
        const rad = 50;

        for (let i = 1; i <= numPoints; i++) {
            // x & y coordinates of the current point
            const theta = i * angleStep;

            const x = 60 + Math.cos(theta) * rad;
            const y = 60 + Math.sin(theta) * rad;

            // store the point
            points.push({
                x: x,
                y: y,
                /* we need to keep a reference to the point's original {x, y} coordinates
                for when we modulate the values later */
                originX: x,
                originY: y,
                // more on this in a moment!
                noiseOffsetX: Math.random() * 1000,
                noiseOffsetY: Math.random() * 1000,
            });
        }

        return points;
    }

    // map a number from 1 range to another
    map(n, start1, end1, start2, end2) {
        return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
    }

    getPath() {
        // Add noise to points
        for (let i = 0; i < this.points.length; i++) {
            const point = this.points[i];

            // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
            const nX = this.noise(point.noiseOffsetX, point.noiseOffsetX);
            const nY = this.noise(point.noiseOffsetY, point.noiseOffsetY);
            // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
            const x = this.map(nX, -1, 1, point.originX - 10, point.originX + 10);
            const y = this.map(nY, -1, 1, point.originY - 10, point.originY + 10);

            // update the point's current coordinates
            point.x = x;
            point.y = y;

            // progress the point's x, y values through "time"
            point.noiseOffsetX += this.noiseStep;
            point.noiseOffsetY += this.noiseStep;
        }

        // generate a smooth continuous curve based on points
        return spline(this.points, 1, true);
    }
}