import React from 'react';
import { Link } from 'react-router-dom';
import './styles/nav.scss';
import {animateNavIndicator, hideNavIndicator} from "../animations/transitions";
import thumbs_up from "../assets/avatar.png";

export default class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.boxRef = React.createRef();

        this.state = {};

        this.links = [
            {
                // Home placeholder
                ref: React.createRef()
            },
            {
                title: "Projects",
                to: "/projects",
                ref: React.createRef()
            },
            {
                title: "Groove",
                to: "/groove",
                ref: React.createRef()
            }
        ];
    }

    render() {
        return (
            <nav className="nav container">
                <div ref={this.boxRef} className="nav-indicator"></div>

                <Link ref={this.links[0].ref} to="/" className="nav-item">
                    <img src={thumbs_up} alt={"Avatar"} />
                </Link>

                <div className="nav-right">
                    {
                        this.links.slice(1).map((link, index) => (
                            <Link ref={this.links[index + 1].ref} class={`nav-item ${this.state.path === link.to ? "nav-item--active" : ""}`} to={link.to}>{link.title}</Link>
                        ))
                    }
                </div>
            </nav>
        );
    }

    findCurrentLink(hostname) {
        let currentLink = null;

        for (let n in this.links) {
            let link = this.links[n];

            if (!link.ref.current) continue;

            if (hostname === link.ref.current.getAttribute("href")) {
                currentLink = link.ref.current;
                break;
            }
        }

        return currentLink;
    }

    animate(pathname) {
        this.setState({
            path: pathname
        });

        if (!this.boxRef.current) return; // No nav pointer ref found

        let currentItemRef = this.findCurrentLink(pathname);
        if (!currentItemRef) {
            hideNavIndicator(this.boxRef.current);

            return; // No nav item found for this path
        }

        animateNavIndicator(this.boxRef.current, currentItemRef, pathname !== "/");
    }
}