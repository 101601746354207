import './styles/Projects.scss';

import background_dtw from "../assets/screenshots/dtw.png";
import background_grooveshare from "../assets/screenshots/grooveshare.png"
import background_nt from "../assets/screenshots/nt.jpg"
import background_stats from "../assets/screenshots/stats.png"
import {useEffect} from "react";

// https://gitlab.com/api/v4/users/0x6c77/projects


function Projects() {
    const projects = [
        {
            title: "Defend the Web",
            links: [
                {
                    title: "Live site",
                    url: "https://defendtheweb.net/"
                },
                {
                    title: "View on GitLab",
                    url: "https://gitlab.com/DefendTheWeb/website"
                }
            ],
            description: <p>A purpose built social and educational platform for teaching users about security, focusing on web based security. All aspects of the website were built from scratch, including UMS, CMS, forum and levels. The site now has a thriving community with a suite of 40+ challenging levels.</p>,
            tags: ["OO-PHP", "MySQL", "CSS3 (SASS)", "Node.js", "Python"],
            screenshot: background_dtw
        },
        {
            title: "Grooveshare",
            links: [
                {
                    title: "Live site",
                    url: "https://grooveshare.co.uk/"
                }
            ],
            description: <p>A project to allow groups of users to share the experience of listening to their favourite music while not being physically together. Listeners are able to create stations and listen together in sync while suggesting new tracks. Takes advantage of the Spotify REST API to control music playback via Spotify Connect devices.</p>,
            tags: ["Laravel", "React", "REST", "NodeJS"],
            screenshot: background_grooveshare
        },
        {
            title: "National Trust",
            description: <p>An interactive display for a National Trust welcome center. Offering image galleries, videos, live streams and long form content for guests.</p>,
            tags: ["Java", "Android"],
            screenshot: background_nt
        },
        {
            title: "Wordle Stats",
            links: [
                {
                    title: "Live site",
                    url: "https://wordle.lward.co.uk/"
                },
                {
                    title: "View on GitLab",
                    url: "https://gitlab.com/0x6C77/wordle-stats"
                }
            ],
            description: <p>Website to parse and display Wordle stats from WhatsApp groups.</p>,
            tags: ["React"],
            screenshot: background_stats
        }
    ]

    useEffect(() => {
        document.title = "Projects | Luke Ward"
    }, []);

    return (
        <div className="container">
            <h1>Latest projects</h1>

            {
                projects.map(project => (
                    <div className="project project--right">
                        <div className="project-info">
                            <h3>
                                {
                                    project.links && project.links.length ?
                                        <a href={project.links[0].url} target="_blank" rel="noreferrer" title="Live site">{ project.title }</a>
                                    :
                                        project.title
                                }
                            </h3>
                            {
                                project.links && project.links.length ?
                                    <div className="project-links">
                                        {
                                            project.links.map(link => (
                                                <a href={link.url} target="_blank" rel="noreferrer" title="Live site">{ link.title }</a>
                                            ))
                                        }
                                    </div>
                                    : null
                            }
                            <div className="project-description">
                                { project.description }
                            </div>
                            {
                                project.tags && project.tags.length ?
                                    <ul className="tags">
                                        {
                                            project.tags.map(tag => (
                                                <li>{ tag }</li>
                                            ))
                                        }
                                    </ul>
                                : null
                            }
                        </div>

                        <div className="project-screenshot">
                            <img src={project.screenshot} alt={"Screenshot of Defend the Web"}/>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default Projects;