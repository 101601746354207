import './styles/Groove.scss'
import React from 'react';
import axios from 'axios';

import ColorThief from "colorthief";

class Groove extends React.Component {
    endpoint = "https://ws.audioscrobbler.com/2.0/";
    key = "2a52fb01c91cf236f147356e0593418d";
    username = "under_your_tree";

    constructor(props) {
        super(props);
        this.state = {};

        this.renderCard = this.renderCard.bind(this);
    }

    componentDidMount() {
        console.log("Load some stuff");
        document.title = "Groove | Luke Ward"

        this.getNowPlaying().then(track => {
            this.setState({
                'nowPlaying': track
            });
        });

        this.getTopArtists().then(track => {
            this.setState({
                'topArtists': track
            });
        });

        this.getTopTracks().then(track => {
            this.setState({
                'topTracks': track
            });
        });
    }

    getPalette = (url) => {
        let self = this;
        return new Promise((resolve, reject) => {
            if (!url) {
                reject();
            }
            const image = new Image();
            image.src = url;
            image.crossOrigin = 'Anonymous';

            image.onload = function () {
                const colorThief = new ColorThief();
                const palette = colorThief.getPalette(this, 2);

                let background = palette[0],
                    highlight = palette[1];

                if (self.luminance(...background) > self.luminance(...highlight)) {
                    background = palette[1];
                    highlight = palette[0];
                }

                let contrast = self.contrast(background, highlight);
                if (contrast > 4) {
                    resolve({
                        highlight: highlight,
                        background: background
                    });
                } else {
                    if (self.contrast(background, [176, 176, 176]) > 4) {
                        resolve({
                            highlight: [176, 176, 176],
                            background: background
                        });
                    } else {
                        resolve({
                            highlight: [50, 50, 50],
                            background: background
                        });
                    }
                }
            }
        });
    }

    luminance = (r, g, b) => {
        var a = [r, g, b].map(function (v) {
            v /= 255;
            return v <= 0.03928
                ? v / 12.92
                : Math.pow( (v + 0.055) / 1.055, 2.4 );
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }
    contrast = (rgb1, rgb2) => {
        var lum1 = this.luminance(rgb1[0], rgb1[1], rgb1[2]);
        var lum2 = this.luminance(rgb2[0], rgb2[1], rgb2[2]);
        var brightest = Math.max(lum1, lum2);
        var darkest = Math.min(lum1, lum2);
        return (brightest + 0.05)
            / (darkest + 0.05);
    }

    rgbToString = rgb => {
        return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}`;
    }

    getTopArtists(limit = 10, period = "overall") {
        return axios.get(`${this.endpoint}?method=user.gettopartists&user=${this.username}&api_key=${this.key}&format=json&limit=${limit}&period=${period}`)
            .then(result => {
                const artists = result.data.topartists.artist.map(data => {
                    const artist = {};
                    artist.playcount = data.playcount;
                    artist.artist = data.name;

                    return artist;
                });

                return artists;
            });
    }

    getTopTracks(limit = 10, period = "overall") {
        return axios.get(`${this.endpoint}?method=user.gettoptracks&user=${this.username}&api_key=${this.key}&format=json&limit=${limit}&period=${period}`)
            .then(result => {
                console.log(result.data.toptracks.track);
                const tracks = result.data.toptracks.track.map(data => {
                    const track = {};
                    track.playcount = data.playcount;
                    track.title = data.name;
                    track.artist = data.artist.name;

                    return track;
                });

                return tracks;
            });
    }

    getNowPlaying() {
        return axios.get(`${this.endpoint}?method=user.getrecenttracks&user=${this.username}&api_key=${this.key}&format=json&limit=1`)
            .then(result => {
                const latest = result.data.recenttracks.track[0];

                const track = {};
                track.playing = latest['@attr'] && latest['@attr'].nowplaying === "true";
                track.title = latest.name;
                track.artist = latest.artist['#text'];
                track.album = latest.album['#text'];

                let image = latest.image.find(img => img.size === "large");
                if (!image || !image['#text']) {
                    track.image = "https://lastfm.freetls.fastly.net/i/u/64s/2a96cbd8b46e442fc41c2b86b821562f.png";
                } else {
                    track.image = image['#text'];

                    this.getPalette(track.image).then(data => {
                        this.setState({
                            palette: data
                        })
                    });
                }

                return track;
            });
    }

    renderCard(track) {
        if (!track) return;

        let styles = {};
        if (track.image) {
            styles = {
                "color": this.state.palette ? this.rgbToString(this.state.palette.highlight) : "",
                "borderColor": this.state.palette ? this.rgbToString(this.state.palette.highlight) : "",
                "backgroundColor": this.state.palette ? this.rgbToString(this.state.palette.background) : ""
            }
        }

        return (
            <div className="card card--groove" style={styles}>
                {
                    track.image ?
                        <div className="card-image"
                             style={{"backgroundImage": `url('${track.image}')`}}>
                        </div>
                    : null
                }
                <div className="card-content">
                    <h3 className="card-title">{ track.title ? track.title : track.artist }</h3>
                    { track.title && track.artist ? track.artist : null }

                    {
                        track.playcount ?
                            <div className="card-playcount">{ new Intl.NumberFormat('en-GB').format(track.playcount) } play{ track.playcount !== 1 ? 's' : '' }</div>
                        : null
                    }
                </div>
            </div>
        );
    }

    render() {
        let nowPlaying = this.renderCard(this.state.nowPlaying);

        let topTracks = this.state.topTracks ? this.state.topTracks.map(this.renderCard) : null;

        let topArtists = this.state.topArtists ? this.state.topArtists.map(this.renderCard) : null;

        return (
            <div className="container">
                <div className="f-right powered-by">
                    Powered by
                    <a href="https://www.last.fm/user/under_your_tree" target="_blank" rel="noreferrer" title="Last.fm">
                        <i className="icon-lastfm"></i>
                    </a>
                </div>

                <h1>Last played</h1>
                { nowPlaying }

                <div className="columns">
                    <div className="column">
                        <h1>Top Tracks</h1>
                        { topTracks }
                    </div>

                    <div className="column">
                        <h1>Top Artists</h1>
                        { topArtists }
                    </div>
                </div>
            </div>
        );
    }
}

export default Groove;
