import { Power1, gsap } from 'gsap';

const navIndicatorPaddingVertical = 5;
const navIndicatorPaddingHorizontal = 10;

const getHomeTimeline = (node, delay) => {
    const timeline = gsap.timeline({ paused: true });
    // const sections = node.querySelectorAll('.section');
    const sections = node.querySelectorAll('h1, .project, .column');

    timeline
        .from(node,{ display: "none", autoAlpha: 0, duration: 0.1, delay})
        .from(sections, { autoAlpha: 0, x: -25, duration: 0.375, ease: Power1.easeOut, stagger: 0.1 });

    return timeline;
}

export const animateIn = (pathname, node, appears) => {
    const delay = appears ? 0 : 0.4;
    let timeline

    timeline = getHomeTimeline(node, delay);

    // if (pathname === '/')
    //     timeline = getHomeTimeline(node, delay);
    // else
    //     timeline = getDefaultTimeline(node, delay);

    window
        .loadPromise
        .then(() => requestAnimationFrame(() => timeline.play()))
}

export const animateOut = (node) => {
    gsap.timeline().to(node, { autoAlpha: 0, ease: Power1.easeOut, duration: 0.3 });
}

export const animateNavIndicator = (indicator, item, visible = true) => {
    const timeline = gsap.timeline();

    let paddingHorizontal = visible ? navIndicatorPaddingHorizontal : 0,
        paddingVertical = visible ? navIndicatorPaddingVertical : 0;

    timeline.to(indicator, {
        x: item.offsetLeft - paddingHorizontal,
        y: item.offsetTop - paddingVertical,
        ease: Power1.easeOut,
        duration: 0.1
    }).to(indicator, {
        width: item.offsetWidth + (paddingHorizontal * 2),
        height: item.offsetHeight + (paddingVertical * 2),
        ease: Power1.easeIn,
        duration: 0.1
    }).to(indicator, {
        opacity: 1,
        borderRadius: (visible ? 5 : 200) + 'px',
        duration: 0.1
    });
}

export const hideNavIndicator = indicator => {
    gsap.timeline().to(indicator, {
        autoAlpha: 0,
        ease: Power1.easeOut,
        duration: 0.1
    })
}


window.loadPromise = new Promise(function(resolve) {
    window.addEventListener('load', resolve);
});